import { useState } from "react"

const useFields = (initialFields = {}) => {
  const [fields, setFields] = useState({ ...initialFields })
  const handleChange = (updatedFields) => {
    setFields(prevFields => ({
      ...prevFields,
      ...updatedFields
    }))
  }
  return [fields, handleChange]
}

export default useFields