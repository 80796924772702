import React from 'react'
import { ChevronLeftIcon } from '@heroicons/react/outline'
import { useNavigate } from 'react-router-dom'

const Header = ({ showBackButton }) => {
  const navigate = useNavigate()
  const goToMenu = () => navigate('/')
  return (
    <div className='header w-full bg-black h-16 flex justify-center relative items-center md:h-20 sticky top-0 z-10'>
      {showBackButton && (
        <ChevronLeftIcon className='text-sandybrown absolute h-1/2 left-2 cursor-pointer' onClick={goToMenu}/>
      )}
      <img src='/logo.png' alt='logo' className='m-2 h-2/3'/>
    </div>
  )
}

export default Header