import axios from 'axios'
import uniq from 'lodash/uniq'
import flatten from 'lodash/flatten'
import uniqBy from 'lodash/uniqBy'
import intersection from 'lodash/intersection'
import snakeCase from 'lodash/snakeCase'
import difference from 'lodash/difference'

const API_ENDPOINT = 'https://nutrition-api.dnaproto.net'
const TAG_IMAGE_ENDPOINT_OUTLINE = '/images/tags/outline'
const TAG_IMAGE_ENDPOINT_ICON = '/images/tags/icons'

const TAG_IMAGE_ENDPOINT_SOLID = '/images/tags/solid'

const defaultCategories = [
  'Specials', 'House Delights', 'Pizza & Breads', 'Soups & Salads',
  'Sides', 'Pasta', 'Filled Pastas', 'Entrees', 'Dessert'
]

const defaultPersonalize = {
  hungry_for: ['Seafood', 'Chicken','Meat', 'Pasta',  'Pizza','Bread', 'Veggies' , 'Dessert'],
  special_diet: ['Vegetarian', 'Low Carb', 'Low Fat'],
  food_restrictions: ['No Eggs', 'No Nuts', 'No Shellfish', 'No Dairy', 'No Soybean', 'No Gluten']
}

const sortByDay = (items) => {
  // separate items per ingredient variation
  items = items.reduce((arr, item) => {
    if (item.variation_ingredients.length) {
      return [
        ...arr,
        ...item.variation_ingredients.map(variation_ingredient => ({
          ...item,
          core_ingredients: [...item.core_ingredients, variation_ingredient]
        }))
      ]
    }
    return [...arr, item]
  }, [])
  //
  let sorted = []
  const currentDay = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'][new Date().getDay()] || 'sunday'
  const sundays = items.filter(item => item.name.toLowerCase().includes('sunday'))
  const mondays = items.filter(item => item.name.toLowerCase().includes('monday'))
  const tuesdays = items.filter(item => item.name.toLowerCase().includes('tuesday'))
  const wednesdays = items.filter(item => item.name.toLowerCase().includes('wednesday'))
  const thrusdays = items.filter(item => item.name.toLowerCase().includes('thursday'))
  const fridays = items.filter(item => item.name.toLowerCase().includes('friday'))
  const saturdays = items.filter(item => item.name.toLowerCase().includes('saturday'))
  if (['sunday', 'friday', 'saturday'].includes(currentDay)) {
    sorted = [
      ...sundays,
      ...mondays,
      ...tuesdays,
      ...wednesdays,
      ...thrusdays,
      ...fridays,
      ...saturdays,
      ...items
    ]
  } else if (currentDay === 'monday') {
    sorted = [
      ...mondays,
      ...tuesdays,
      ...wednesdays,
      ...thrusdays,
      ...fridays,
      ...saturdays,
      ...sundays,
      ...items
    ]
  } else if (currentDay === 'tuesday') {
    sorted = [
      ...tuesdays,
      ...wednesdays,
      ...thrusdays,
      ...fridays,
      ...saturdays,
      ...sundays,
      ...mondays,
      ...items
    ]
  } else if (currentDay === 'wednesday') {
    sorted = [
      ...wednesdays,
      ...thrusdays,
      ...fridays,
      ...saturdays,
      ...sundays,
      ...mondays,
      ...tuesdays,
      ...items
    ]
  } else if (currentDay === 'thursday') {
    sorted = [
      ...thrusdays,
      ...fridays,
      ...saturdays,
      ...sundays,
      ...mondays,
      ...tuesdays,
      ...wednesdays,
      ...items
    ]
  }
  
  return sorted.map(e => ({
    ...e,
    display_tags: [],
    category: e.category ? e.category.trim() : e.category
  }))
    .filter(e => defaultCategories.includes(e.category))
}

export const requestData = async => {
  const endpoints = [
    `${API_ENDPOINT}/menu-item/search?limit=1000`,
    `${API_ENDPOINT}/food-item/search?limit=1000`,
    `${API_ENDPOINT}/food-tag/search?limit=1000`,
  ]
  return axios
    .all(
      endpoints.map((endpoint) => axios.get(endpoint))
    )
    .then((res) => {
      
      return {
        menu_items: sortByDay(res[0].data.items),
        food_items: res[1].data.items,
        food_tags: res[2].data.items
      }
    })
}

export const autoTagFoodItems = (data) => {
  const { menu_items, food_items, food_tags } = data
  data.menu_items = menu_items.map(menu_item => {

    const ingredients = menu_item.core_ingredients
      .map(ingredient => food_items.find(food_item => food_item.id === ingredient.food_item_id))
      .filter(Boolean)
      .map(ingredient => ({
        ...ingredient,
        class_tags: ingredient.class_tags
          .map(tag_id => food_tags.find(food_tag => food_tag.id === tag_id)?.name || tag_id)
          .filter(Boolean)
          .map(tag => {
            if (tag === 'Lowfat') {
              return 'Low Fat'
            }
            if (tag === 'Lowcarb') {
              return 'Low Carb'
            }
            if (tag === 'Bread') {
              return 'Bread'
            }
            if (tag === 'Vegetable') {
              return 'Veggies'
            }
            return tag
          })
      }))

    const removeTheseTags = ['Vegetarian', 'Low Carb', 'Low Fat']
    let tags = uniq(flatten(ingredients.map(ingredient => ingredient.class_tags)))
      .filter(tag => !removeTheseTags.includes(tag))
    if (ingredients.length) {
      if (ingredients.every(ingredient => ingredient.class_tags.includes('Vegetarian'))) {
        tags = [...tags, 'Vegetarian']
      }
      if (ingredients.every(ingredient => ingredient.class_tags.includes('Low Carb'))) {
        tags = [...tags, 'Low Carb']
      }
      if (ingredients.every(ingredient => ingredient.class_tags.includes('Low Fat'))) {
        tags = [...tags, 'Low Fat']
      }
    }

    if (!tags.includes('Eggs')) {
      tags = [...tags, 'No Eggs']
    }
    if (!tags.includes('Nuts')) {
      tags = [...tags, 'No Nuts']
    }
    if (!tags.includes('Shellfish')) {
      tags = [...tags, 'No Shellfish']
    }
    if (!tags.includes('Dairy')) {
      tags = [...tags, 'No Dairy']
    }
    if (!tags.includes('Soybean')) {
      tags = [...tags, 'No Soybean']
    }
    if (!tags.includes('Gluten')) {
      tags = [...tags, 'No Gluten']
    }

    if (!menu_item.core_ingredients.length) {
      if (menu_item.category === 'Dessert') {
        tags = [...tags, 'Dessert']
      }
    }

    // make No tags last
    const with_no_tags = tags.filter(tag => tag.includes('No'))
    const without_no_tags = tags.filter(tag => !tag.includes('No'))

    tags = uniq([...without_no_tags, ...with_no_tags])
    menu_item.auto_tags = tags
    return menu_item
  })
  return data
}

export const filterMenuItems = async (menu_items, tags, personalize) => {
  menu_items = sortByDay(menu_items)
  return new Promise((res) => {
    const fixedItems = menu_items.filter(menu_item => menu_item.name.includes('***'))
    let filtered = menu_items.filter(menu_item => {
      const hungryForTags = intersection(tags, defaultPersonalize.hungry_for)
      const otherTags = difference(tags, hungryForTags)
      if (hungryForTags.length) {
        if (otherTags.length) {
          return !!intersection(hungryForTags, menu_item.auto_tags).length
            && otherTags.every(tag => menu_item.auto_tags.includes(tag))
        }
        return !!intersection(hungryForTags, menu_item.auto_tags).length
      }
      return tags.every(tag => menu_item.auto_tags.includes(tag))
    })
      .map(e => ({
        ...e,
        display_tags: formatTags(
          intersection(getTagsFromPersonalize(personalize), e.auto_tags)
        )
      }))
    if (tags.length) {
      filtered = filtered
        .map(e => ({ ...e, best_match: true }))
    }

    filtered = filtered.sort((a, b) => {
      return b.display_tags.length - a.display_tags.length
    })

    const result = uniqBy([
      ...filtered,
      ...fixedItems
    ], 'id')

    return res(result)
  })
}

export const filterMenuItems2 = (menu_items, tags, personalize) => {
  let filtered = menu_items.filter(menu_item => {
    const hungryForTags = intersection(tags, defaultPersonalize.hungry_for)
    const otherTags = difference(tags, hungryForTags)
    if (hungryForTags.length) {
      if (otherTags.length) {
        return !!intersection(hungryForTags, menu_item.auto_tags).length
          && otherTags.every(tag => menu_item.auto_tags.includes(tag))
      }
      return !!intersection(hungryForTags, menu_item.auto_tags).length
    }
    return tags.every(tag => menu_item.auto_tags.includes(tag))
  })
    .map(e => ({
      ...e,
      display_tags: formatTags(
        intersection(getTagsFromPersonalize(personalize), e.auto_tags)
      )
    }))
  if (tags.length) {
    filtered = filtered
      .map(e => ({ ...e, best_match: true }))
  }
  return filtered
}


export const groupMenuItemsByCategory = (menu_items, tags, personalize) => {
  menu_items = sortByDay(menu_items)
  return defaultCategories.reduce((arr, key) => {
    const items = menu_items.filter(e => e.category === key)
    return [
      ...arr,
      {
        all: uniqBy(items, 'id'),
        filtered: filterMenuItems2(items, tags, personalize),
        category: items.length && items[0].category
      }
    ]
  }, [])
}

export const requestMenu = async (tags) => {
  return axios
    // .get(`${API_ENDPOINT}/menu-item/search?limit=1000`, { params: { class_tags: tags.join(',') } })
    .get(`${API_ENDPOINT}/menu-item/search?limit=1000`)
    .then(res => {
      if (tags.length > 1) {
        res.data.items = res.data.items.map(e => ({
          ...e,
          best_match: true
        }))
      }
      return res.data.items
    })
}

export const formatTags = (tags, type = 'outline') => {
  const getImage = name => {
    return {
      outline: {
        'Seafood': `${TAG_IMAGE_ENDPOINT_ICON}/seafood.png`,
        'Pasta': `${TAG_IMAGE_ENDPOINT_ICON}/pasta.png`,
        'Meat': `${TAG_IMAGE_ENDPOINT_ICON}/meat.png`,
        'Chicken': `${TAG_IMAGE_ENDPOINT_ICON}/chicken.png`,
        'Vegetarian': `${TAG_IMAGE_ENDPOINT_ICON}/vegetarian.png`,
        'Low Carb': `${TAG_IMAGE_ENDPOINT_ICON}/low-carb.png`,
        'Low Fat': `${TAG_IMAGE_ENDPOINT_ICON}/low-fat.png`,
        'Pizza': `${TAG_IMAGE_ENDPOINT_ICON}/pizza.png`,
        'Bread': `${TAG_IMAGE_ENDPOINT_ICON}/bread.png`,
        'No Eggs': `${TAG_IMAGE_ENDPOINT_ICON}/no_egg.png`,
        'No Nuts': `${TAG_IMAGE_ENDPOINT_ICON}/no-nut.png`,
        'No Shellfish': `${TAG_IMAGE_ENDPOINT_ICON}/no_shellfish.png`,
        'No Dairy': `${TAG_IMAGE_ENDPOINT_ICON}/no_dairy.png`,
        'No Soybean': `${TAG_IMAGE_ENDPOINT_ICON}/no-soybean.png`,
        'No Gluten': `${TAG_IMAGE_ENDPOINT_ICON}/no_gluten.png`,
        'Veggies': `${TAG_IMAGE_ENDPOINT_ICON}/veggies.png`,
        'Dessert': `${TAG_IMAGE_ENDPOINT_ICON}/dessert.png`,
      },
      solid: {
        'Seafood': `${TAG_IMAGE_ENDPOINT_SOLID}/seafood.png`,
        'Pasta': `${TAG_IMAGE_ENDPOINT_SOLID}/pasta.png`,
        'Meat': `${TAG_IMAGE_ENDPOINT_SOLID}/meat.png`,
        'Chicken': `${TAG_IMAGE_ENDPOINT_SOLID}/chicken.png`,
        'Vegetarian': `${TAG_IMAGE_ENDPOINT_SOLID}/vegetarian.png`,
      }
    }[type][name]
  }
  tags = tags.map(e => ({
    name: e,
    image: getImage(e)
  })).filter(e => e.image)
  return tags
}

export const getTagsFromPersonalize = (personalize = {}) => {
  return uniq(flatten(Object.values(personalize))).filter(e => e !== 'None')
}