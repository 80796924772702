import React, { useEffect, useRef, useState } from 'react'
import Header from '../components/Header'
import cn from 'classnames'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import Pill from '../components/Pill'
import BottomSheet from '../components/BottomSheet'
import gsap from 'gsap'
import List from '../components/List'
import { getTagsFromPersonalize, formatTags } from '../data/tools'
import intersection from 'lodash/intersection'
import orderBy from 'lodash/orderBy'
import ViewallToggle from '../components/ViewallToggle'

const Menu = (props) => {
  const {
    loading,
    displayItems,
    onFilter,
    displayCategories,
    activeCategory,
    setItem,
    personalize,
    onPersonalize,
    hasPersonalized,
    clearOptions,
    showScrollToTop,
    disableNavigationPills,
    dataByCategory,
    scrollToTop,
    viewAll,
    viewAllToggler,
    activateCategory,
    isWideScreen,
    device
  } = props

  const [isBottomSheetOpen, setBottomSheetOpen] = useState(false)
  const [hasBottomSheetChanges, setHasBottomSheetChanges] = useState(false)
  const [clickedImage, setClickedImage] = useState(null)
  const bottomSheetRef = useRef(null)
  const defaultBottomHeaderHeight = 100
  const defaultBottomSheetTopDistance = window.innerHeight - defaultBottomHeaderHeight
  const bottomHeaderHeight = isBottomSheetOpen
    ? defaultBottomHeaderHeight
    : window.innerHeight - (bottomSheetRef?.current?.getBoundingClientRect().top || defaultBottomSheetTopDistance)
  let scrl = useRef(null);
  const navigate = useNavigate()

  //GSAP SCROLL ANIMATION
  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);

  //Slide click
  const slide = (shift) => {
    scrl.current.scrollLeft += shift;
    setscrollX(scrollX + shift);

    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  //Anim
  const anim = (e) => {
    gsap.from(e.target, { scale: 1 });
    gsap.to(e.target, { scale: 1.5 });
  };
  const anim2 = (e) => {
    gsap.from(e.target, { scale: 1.5 });
    gsap.to(e.target, { scale: 1 });
  };

  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };
  //END GSAP

  const handleCategoryClick = category => {
    const prevActiveCategoryName = document.querySelector('.pill.active button')?.dataset?.category
    activateCategory(category)
    onFilter(category, getTagsFromPersonalize(personalize))
    const section = document.getElementById(category)
    if (section && category !== prevActiveCategoryName) {
      const prevIndex = displayCategories.indexOf(category) - 1
      let prevCategory = null
      if (prevIndex >= 0) {
        prevCategory = document.getElementById(displayCategories[prevIndex])
      }
      let top = section.offsetTop + (isWideScreen ? 30 : 10)
      if (prevCategory && prevCategory.childNodes[1].innerHTML.includes('No items')) {
        top = section.offsetTop + 40
      } else if (hasPersonalized) {
        top += 10
      }
      window.scrollTo({ behavior: 'smooth', top })       
    }
  }

  const toggleBottomSheet = () => {
    setBottomSheetOpen(!isBottomSheetOpen)
    if (isBottomSheetOpen && hasBottomSheetChanges) {
      disableNavigationPills(false)
      onFilter(activeCategory, getTagsFromPersonalize(personalize), { isPersonalize: true })
      setHasBottomSheetChanges(false)
      scrollToTop()
    }
  }
  const onRemovePill = () => {
    onFilter(activeCategory, getTagsFromPersonalize(personalize), { isPersonalize: true })
    setHasBottomSheetChanges(false)
    scrollToTop()
  }

  const goToItemDetails = (item) => {
    setItem(item)
    navigate('/item')
  }

  const handleClickImage = item => {
    setClickedImage(item)
  }

  useEffect(() => {
    document.body.style.overflow = clickedImage ? 'hidden' : 'auto' 
  },[clickedImage])

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const openBottomSheet = searchParams.get('personalize_menu') === 'true'
    setBottomSheetOpen(openBottomSheet)
  }, [])

  const formattedDataByCategory = dataByCategory.map(section => {
    const allTags = formatTags(getTagsFromPersonalize(personalize))
    const allTagNames = allTags.map(e => e.name)
    section.all = section.all.map(item => {
      const remove = "/api/photo/";
      const photoName = item.photo.thumb.replace(remove,"");
      item.photo.name = photoName;
      const found = displayItems.find(e => e.id === item.id)
      item.display_tags = (hasPersonalized && found) ? found.display_tags : []
      item.best_match = !!(hasPersonalized && found && found.best_match)

      const matchTags = intersection(item.auto_tags, allTagNames)
      let displayTags = item.display_tags
      if (!item.best_match) {
        displayTags = formatTags(matchTags)
      }
      item.tagCount = displayTags.length
      return item
    })
    const bestMatchItems = section.all.filter(e => e.best_match)
    const notBestMatchItems = section.all.filter(e => !e.best_match)
    section.all = orderBy([...bestMatchItems, ...notBestMatchItems], 'tagCount', 'desc')
    if (hasPersonalized) {
      const bestMatches = section.all.filter(e => e.best_match)
      const partialMatches = section.all.filter(e => !e.best_match && !!e.tagCount)
      if (bestMatches.length && partialMatches.length) {
        section.description = `${bestMatches.length} Best Match and ${partialMatches.length} Partial Match in ${section.category}`
      } else if (bestMatches.length){
        section.description = `${bestMatches.length} Best Match in ${section.category}`
      } else if (partialMatches.length) {
        section.description = `${partialMatches.length} Partial Match in ${section.category}`
      } else {
        section.description = `No items match your preferences in ${section.category}`
      }
    }
    return section
  }).filter(section => !!section.all.length)

  return (
    <>
      {clickedImage && (
        <ModalImageContainer
          className="fixed top-0 left-0 w-full h-full lg:mx-auto bg-black/70 z-20 flex justify-center items-center cursor-pointer"
          onClick={() => handleClickImage(null)}
          >
            <div className='max-w-[500px] md:max-w-[700px] w-[40vh] md:w-[60vh] h-[40vh] md:h-[60vh] relative'>
              <img className='object-cover w-full h-full rounded-2xl' src={clickedImage.image} />
              <div className='w-full absolute bottom-2 flex justify-center'>
                <p className='w-[90%] text-center bg-white p-1 md:p-2 rounded-md font-bold md:text-2xl'>{clickedImage.name.replace('***', '')}</p>
              </div>
            </div>
        </ModalImageContainer>
      )}
      <div className='w-full bg-white pb-4 lg:min-h-screen relative'>
        <Header />
        <div className='flex items-center px-4 sticky top-[64px] z-10 bg-white shadow-md'>

          <PillsContainer id='pills-container' className={cn(
            'flex flex-nowrap overflow-x-auto lg:flex-nowrap lg:pt-0 px-4 sticky top-0 bg-white z-[5]',
            {
              'pb-2 pt-4': showScrollToTop,
              'pb-0 pt-2': !showScrollToTop,
            }
          )} ref={scrl} onScroll={scrollCheck} >

            {displayCategories.map((e, index) => (
              <Pill
                key={e}
                disabled={loading}
                index={index}
                type='category'
                name={e}
                isActive={e === activeCategory}
                formattedDataByCategory={formattedDataByCategory}
                onClick={handleCategoryClick.bind(null, e)}
              />
            ))}

          </PillsContainer>
            {scrollX !== 0 && (
              <span className='h-full w-16'>
                <button
                  className={cn(
                      'prev',
                      {
                        'pt-3 md:mt-1 md:pt-[0.45rem]' : !showScrollToTop,
                        'pt-3 md:mb-2 md:pt-[0.30rem]' : showScrollToTop
                      },
                    )}
                  onClick={() => slide(-50)}
                >
                  <img src='/images/left-icon.png' alt='' />
                </button>
              </span>
            )}
            {!scrolEnd && (
              <span className='h-full w-16'>
                <button
                  className={cn(
                    'next',
                    {
                     'pt-[0.95rem] md:mt-1 md:pt-[0.60rem]' : !showScrollToTop,
                     'pt-[0.95rem] md:mb-2 md:pt-[0.60rem]' : showScrollToTop
                    },
                  )}
                  onClick={() => slide(+50)}
                >
                  <img src='/images/left-icon.png' alt='' style={{ transform: 'rotate(180deg)' }} />
                </button>
              </span>
            )}
        </div>
        {loading && (
          <div className='flex flex-col mt-8'>
            <img src='/images/searching.gif' alt='No Results :(' className='h-24 object-contain' />
            <h1 className='animate-pulse text-center md:text-lg mt-8'>Searching {activeCategory}...</h1>
          </div>
        )}
        {/* {!loading && !displayItems.length && (
          <div className='flex flex-col mt-8'>
            <img src='/images/no_results.png' alt='No Results :(' className='h-24 object-contain' />
            <h1 className='text-center md:text-lg mt-4 text-dimgray'>No {hasPersonalized ? 'Matching Items' : 'Result'}</h1>
          </div>
        )} */}
        {
          <ViewallToggle 
            {...personalize}
            device={device}
            showScrollToTop={showScrollToTop}
            viewAll={viewAll}
            viewAllToggler={viewAllToggler}
          />
        }
        <List
          {...props}
          formattedDataByCategory={formattedDataByCategory}
          hasBottomSheetChanges={hasBottomSheetChanges}
          isBottomSheetOpen={isBottomSheetOpen}
          onClickImage={handleClickImage}
          bottomHeaderHeight={bottomHeaderHeight}
        />
        <BottomSheet
          showScrollToTop={showScrollToTop}
          scrollToTop={scrollToTop}
          bottomSheetRef={bottomSheetRef}
          isOpen={isBottomSheetOpen}
          personalize={personalize}
          hasPersonalized={hasPersonalized}
          onPersonalize={onPersonalize}
          onToggle={toggleBottomSheet}
          clearOptions={clearOptions}
          setHasBottomSheetChanges={setHasBottomSheetChanges}
          setBottomSheetOpen={setBottomSheetOpen}
          onRemovePill={onRemovePill}
        />
      </div>
    </>
  )
}

export default Menu

const PillsContainer = styled.div`
  ::-webkit-scrollbar {
    display: none;
  }
`

const ModalImageContainer = styled.div`
  animation: fade .2s linear;
  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`
