import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import { CheckCircleIcon } from '@heroicons/react/outline'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import { formatTags } from '../data/tools'

const ItemDetails = ({ item, hasPersonalized }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0) 
  const navigate = useNavigate()

  useEffect(() => {
    if (!item) {
      navigate('/')
      return
    }
  }, [])
  if (!item) return
  item.cost = item.cost || '$0'
  const tags = formatTags(item.auto_tags, 'solid')
  return (
    <div className='w-full h-full'>
      <Header showBackButton/>
      <div className='flex flex-col w-full'>
        <div className='h-[50vw] lg:h-[300px] w-full relative'>
          <img className='h-full w-full object-cover' src='https://i0.wp.com/www.sourdoughandolives.com/wp-content/uploads/2017/04/Delizie3.jpg?resize=665%2C530&ssl=1' alt='baked lobster tail'/>
          {item.best_match && (
            <div className='absolute bg-yellowgreen bottom-0 left-0 h-[30px] flex px-3 py-1 rounded-tr-xl text-white items-center'>
              <CheckCircleIcon className='h-full'/>
              <h2 className='uppercase ml-1 whitespace-nowrap text-lg'>Best Match</h2> 
            </div>
          )}
        </div>
        <div className='flex flex-col w-full p-2'>
          <p className='text-xs text-sandybrown'>{item.category}</p>
          <div className='flex justify-between text-2xl font-semibold'>
            <h2 className='uppercase'>{item.name}</h2>
            <p className='flex'>
              <span className='text-xs mt-1'>$</span>
              {item.cost.replace('$', '')}
            </p>
          </div>
          <div className='grid grid-cols-2 mt-4'>
            <div
              className={cn(
                'border-b-2 cursor-pointer',
                {
                  'border-b-sandybrown text-sandybrown': activeTabIndex === 0
                }
              )}
              onClick={() => setActiveTabIndex(0)}
            >
              <h3 className='font-semibold'>Personal Insights</h3>
            </div>
            <div
              className={cn(
                'border-b-2 cursor-pointer',
                {
                  'border-b-sandybrown text-sandybrown': activeTabIndex === 1
                }
              )}
              onClick={() => setActiveTabIndex(1)}
            >
              <h3 className='font-semibold'>Ingredients</h3>
            </div>
          </div>
          {activeTabIndex === 0 && (
            <div className='w-full mt-4'>
              <p className='text-sm text-dimgray'>This menu item is the best math based on you personalized menu preferences.</p>
              <div className='grid grid-cols-2 mt-4'>
                {tags.map(e => (
                  <div className='w-full flex items-center' key={e.name}>
                    <img src={e.image} className='h-16' />
                    <p className='text-dimgray text-sm ml-3'>{e.name}</p>
                  </div>
                ))}
              </div>
              <h3 className='text-sm font-semibold mt-4'>Ingredients</h3>
              <p className='text-dimgray text-sm mt-2'>lobster tail, drawn butter</p>
            </div>
          )}
          {activeTabIndex === 1 && (
            <div className='w-full p-4 flex flex-col'>
              <div className='grid grid-cols-3 mt-4'>
                <img className='w-3/5 rounded-md' src='https://images.heb.com/is/image/HEBGrocery/recipe-hm-large/baked-lobster-tails-recipe.jpg'/>
                <div className='col-span-2'>
                  <h3 className='text-xl font-semibold'>Lobster Tail</h3>
                  <p className='text-sm text-dimgray'>pristine white, soft and tender. It's flavor is uniquely mild, with all the richness in texture</p>
                </div>
              </div>
              <div className='grid grid-cols-3 mt-4'>
                <img className='w-3/5 rounded-md' src='https://images.heb.com/is/image/HEBGrocery/recipe-hm-large/baked-lobster-tails-recipe.jpg'/>
                <div className='col-span-2'>
                  <h3 className='text-xl font-semibold'>Lobster Tail</h3>
                  <p className='text-sm text-dimgray'>pristine white, soft and tender. It's flavor is uniquely mild, with all the richness in texture</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ItemDetails