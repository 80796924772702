import React, { useEffect, useState } from 'react'
import cn from 'classnames'

const ViewallToggle = (props) => {

    const {
        food_restrictions,
        hungry_for,
        special_diet,
        viewAll,
        viewAllToggler,
        showScrollToTop,
        device
    } = props

    const [hidden,setHidden] = useState(false)
    
    const handleToggle = () => {
        viewAllToggler()
    }
    
    useEffect(() => {
        if(food_restrictions.length !== 0 || hungry_for.length !== 0 || special_diet.length !== 0){
            setHidden(true)
            return;
        }
        if(viewAll && food_restrictions.length === 0 && hungry_for.length === 0 && special_diet.length === 0){
            viewAllToggler(false)
        }
        setHidden(false)
    },[food_restrictions,hungry_for,special_diet])

    if(hidden){
        return (
            <div 
                className={cn(
                    'view-all-toggle h-10 md:h-14 px-4 sticky z-[10] bg-white shadow-md',
                    {
                       'top-[6.7rem]' : !showScrollToTop,
                       'top-[7rem]' : showScrollToTop
                    }
                )}
            >
                <div className='flex justify-end py-2.5 md:py-4'>
                    <label className={`mr-1 text-[0.865rem] md:text-base md:mb-1 ${device.includes('ios') ? 'mt-[1px]' : 'mt-[0.1rem]'}`}>View All</label>
                    <div className={`md:w-12 mt-[0.05rem] md:h-6 w-9 h-5 flex items-center rounded-full p-1 pr-[0.10rem] cursor-pointer ${!viewAll ? 'bg-lightgray' : `bg-lightgreen`}`}
                        onClick={handleToggle}
                    >
                        <div
                            className={`md:w-5 md:h-5 h-4 w-4 rounded-full shadow-md transform duration-200 ease-in-out bg-slate-50 ${viewAll && 'transform translate-x-3 md:translate-x-5'}`}
                        ></div>
                    </div>
                </div>
            </div>
        );
    }
    return null
}

export default ViewallToggle