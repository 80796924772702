import React from 'react'

const Colors = () => {
  const colors = [
    'whitesmoke',
    'linen',
    'gray',
    'darkgray',
    'sandybrown',
    'yellowgreen',
    'red',
    'mediumslateblue'
  ]
  return (
    <div className='bg-black w-full h-screen'>
      {colors.map(e => (
        <div className={`w-full h-20 bg-${e} border-2 border-black flex justify-center items-center text-2xl font-bold`} key={e}>
          <h1>{e}</h1>
        </div>
      ))}

    </div>
  )
}

export default Colors