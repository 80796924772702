import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import differenceBy from 'lodash/differenceBy'
import difference from 'lodash/difference'
import intersection from 'lodash/intersection'
import { getTagsFromPersonalize, formatTags } from '../data/tools'

const UPLOAD_ENDPOINT = 'https://nutrition-app.dnaproto.net'
// const UPLOAD_ENDPOINT = 'https://nutrition-app.dnaproto.net'
const defaultPersonalize = {
  special_diet: ['Vegetarian', 'Low Carb', 'Low Fat'],
  hungry_for: ['Seafood', 'Pasta', 'Chicken', 'Pizza', 'Bread', 'Meat', 'Veggies'],
  food_restrictions: ['No Eggs', 'No Nuts', 'No Shellfish', 'No Dairy', 'No Soybean', 'No Gluten']
}
const MenuCardItem = ({
  name,
  description_short,
  cost,
  onClick = () => { },
  best_match,
  display_tags: tags,
  photo,
  personalize,
  hasBottomSheetChanges,
  isWideScreen,
  hasPersonalized,
  auto_tags,
  onClickImage,
  device
}) => {
  const nameRef = useRef()
  const descriptionRef = useRef()
  const moreButtonRef = useRef()
  const [isNameClamped, setIsNameClamped] = useState(false)
  const [isDescriptionClamped, setIsDescriptionClamped] = useState(false)
  const [openSeeMore, setOpenSeeMore] = useState(false)
  
  // ? `${UPLOAD_ENDPOINT}/api/photo/${photo.name}?file=true&id=${photo.photo_item_id}` 
  // ? ${UPLOAD_ENDPOINT}${photo.thumb}?file=true&id=${photo.photo_item_id}
  const thumb = photo.thumb || photo.photo_item_id
    ? `${UPLOAD_ENDPOINT}${photo.thumb_file_path}?entity=medium&file=true` 
    : ''

  // const thumbnail = thumb ? `${UPLOAD_ENDPOINT}${photo.thumb}?file=true&id=${photo.photo_item_id}` : '' //thumbnail
  // const thumbnail = thumb ? `${UPLOAD_ENDPOINT}/api/photo/${photo.name}?file=true&id=${photo.photo_item_id}` : '' //thumbnail
  const medium = thumb ? `${UPLOAD_ENDPOINT}${photo.thumb_file_path}?entity=medium&file=true` : '' //thumbnail
  // const original = thumb ? `${UPLOAD_ENDPOINT}${photo.thumb_file_path}?entity=image` : '' //original image
    const toggleSeeMore = () => setOpenSeeMore(!openSeeMore)

  const handleNameClamped = () => {
    if (nameRef) {
      const { clientHeight, scrollHeight } = nameRef.current
      const isClamped = scrollHeight > clientHeight
      setIsNameClamped(isClamped)
    }
  }
  useEffect(handleNameClamped, [nameRef])

  const handleDescriptionClamped = () => {
    if (descriptionRef.current) {
      let moreButtonHeight = moreButtonRef?.current?.clientHeight || 0
      if (moreButtonHeight) {
        moreButtonHeight = moreButtonHeight - 22 // button has top: -22px
      }
      const { clientHeight, scrollHeight } = descriptionRef.current
      const isClamped = hasPersonalized ? 
        (scrollHeight - moreButtonHeight) > clientHeight : scrollHeight  > clientHeight
      setIsDescriptionClamped(isClamped)
    }
  }
  useEffect(handleDescriptionClamped, [descriptionRef])

  useEffect(() => {
    handleNameClamped()
    setTimeout(handleDescriptionClamped, 100)
    if ((!isDescriptionClamped && openSeeMore) && !hasPersonalized) {
      setIsDescriptionClamped(true)
      setOpenSeeMore(false)
    }
  }, [hasBottomSheetChanges,personalize])


  const shouldSeeMore = () => {
    if (!tags.length && !isDescriptionClamped) {
      return false
    }
    return (isDescriptionClamped) && !isWideScreen
  }
  const showSeeMore = shouldSeeMore()
  const allTags = formatTags(getTagsFromPersonalize(personalize))
  const allTagNames = allTags.map(e => e.name)
  const matchTags = intersection(auto_tags, allTagNames)
  let displayTags = tags
  if (!best_match) {
    displayTags = formatTags(matchTags)
  }
  const dontMatchTags = differenceBy(allTags, displayTags, 'name')
  const getDontMatchLabel = () => {
    let labels = []
    const tagNames = difference(allTagNames, matchTags)
    const special_diet = intersection(tagNames, defaultPersonalize.special_diet)
    const hungry_for = intersection(tagNames, defaultPersonalize.hungry_for)
    const food_restrictions = intersection(tagNames, defaultPersonalize.food_restrictions)
    
    if (special_diet.length) {
      labels = [...labels, ...special_diet.map(e => `Not ${e}`)]
    }
    if (hungry_for.length) {
      labels = [...labels, ...hungry_for.map(e => `No ${e}`)]
    }
    if (food_restrictions.length) {
      labels = [...labels, ...food_restrictions.map(e => `Contains ${e.replace('No ', '')}`)]
    }
    return `* Does Not Match Your Preferences: ${labels.join(', ')}`
  }

  return (
    <StyledMenuCardItem className={cn(
      'flex rounded-md overflow-hidden bg-white mt-2 menuCardItem min-h-[170px]',
      {
        'h-[160px] min-h-[unset]': !hasPersonalized,
        // 'min-h-[160px] md:min-h-[180px]': !openSeeMore,
        // 'min-h-[200px]': openSeeMore,
        'min-h-[184px]': showSeeMore && !displayTags.length,
        // 'min-h-[140px] h-[unset]': displayTags.length,
        'border-[#8dce2b] border-2 border-solid' : best_match,
        'border-yellow-400 border-2 border-solid' : !!displayTags.length && !best_match,
        'h-auto': (best_match && ((showSeeMore && openSeeMore) || !isDescriptionClamped)) || openSeeMore
      }
    )} onClick={onClick}>
      <div className='min-h-full relative w-[33%] img-content border-r-[1px] border-slate-300  border-solid'>
        {thumb ? (
          <div className='w-full h-full bg-white flex justify-center items-end md:items-center'>
            <img
              className='object-cover absolute p-[0.2rem] w-[100%] h-full lg:w-[195px] lg:h-[195px] cursor-pointer'
              src={thumb}
              alt={name}
              onClick={() => onClickImage({ name, image: medium })}
              // loading={'lazy'}
            />
          </div>
        ) : (
          <div className='w-full h-full bg-white flex justify-center items-center'>
            {/* <img src='/no-logo.png' className='object-cover p-4 lg:w-[195px] lg:h-[195px]' loading={'lazy'} /> */}
            <img src='/no-logo.png' className='object-cover p-4 lg:w-[195px] lg:h-[195px]' alt='' />
          </div>
        )}
        {!!displayTags.length && (
          <div className={cn(
            'absolute bottom-0 top-[0] h-[35px] left-0 w-[100%] flex p-1 text-white items-center justify-center',
            {
              'bg-yellowgreen': best_match,
              'bg-yellow-400': !best_match,
            }
          )}>
            <h2 className='uppercase whitespace-nowrap font-bold text-[0.8rem] md:text-[1rem]'>
              {best_match ? 'Best Match' : 'Partial Match'}
            </h2>
          </div>
        )}
      </div>
      <div className='col-span-2 relative flex flex-col flex-1 px-4 pt-4 pb-1 min-h-full w-[67%]'>
        <div className='flex justify-between items-start'>
          <h2
            className={cn(
              'text-[14px] font-bold md:text-2xl',
              {
                item_name: !openSeeMore
              }
            )}
            ref={nameRef}
          >{name.replace('***', '')}</h2>
          {!!Number(cost) && (
            <button className=' text-black text-md px-3 flex items-start cursor-auto'>
              <span className='text-[.7rem] md:text-sm mt-[2px] md:mt-[3px]'>$</span>
              <span className='md:text-xl'>{`${cost}`.replace('$', '')}</span>
            </button>
          )}
        </div>
		{/* <div className='h-16 overflow-y-auto'> */}
        <div className=''>
          <div
            className={cn(
              ' text-dimgray text-[13px] md:text-base mt-1 relative overflow-y-auto',
              {
                item_description: !openSeeMore,
                max_clamp: !displayTags.length ,
				with_tags: hasPersonalized && (displayTags.length && !showSeeMore),
                // max_clamp: !displayTags.length || (displayTags.length && !isNameClamped),
                // min_clamp: displayTags.length && isNameClamped
              }
            )}
            ref={(element) => descriptionRef.current = element}
          >{description_short}
          {(showSeeMore && !openSeeMore) && (
			<div className={`see_more hidden ${device}`}>
				<div className='text-sm relative' onClick={toggleSeeMore}>
				<span ref={moreButtonRef} className='bg-white text-[13px] px-1 text-[#2916ff] rounded-md absolute right-0 top-[-20px]'>...more</span>
				</div>
			</div>  
			)}
			{(showSeeMore && openSeeMore) && (
			<span className='text-[#2916ff]' onClick={toggleSeeMore}> ...less</span>
			)}
          </div>
        </div>
        {(hasPersonalized && !!displayTags.length) && (
          <div className='mt-auto w-full'>
            <div className='flex flex-wrap space-between'>
              {displayTags.map((tag, index) => (
                <div key={tag.name + index} className={cn(
                  'flex flex-col items-center mt-1  pr-2 md:w-[75px]'
                )}>
                  <img src={tag.image} className="h-[1.5rem] w-[1.5rem] md:h-7 md:w-7" alt={tag.name}/>
                  <p className='iconName text-dimgray text-[.75rem] md:text-s'>{tag.name}</p>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </StyledMenuCardItem>
  )
}

export default MenuCardItem

const StyledMenuCardItem = styled.div`
  &.menuCardItem{
      box-shadow: 0px 0px 5px 5px #EBEBEB ;
      margin-bottom: 10px;
    }

	@media only screen and (-webkit-min-device-pixel-ratio: 3),
	only screen and (min--moz-device-pixel-ratio: 3),
	only screen and (-o-min-device-pixel-ratio: 3/1),
	only screen and (min-device-pixel-ratio: 3) {

		& .item_name {
		  display: -webkit-box;
		  -webkit-line-clamp: 2;
		  -webkit-box-orient: vertical;
		  overflow: hidden;
		}
		& .item_description {
		  display: -webkit-box;
		  -webkit-line-clamp: 2;
		  -webkit-box-orient: vertical;
		  overflow: hidden;
		  &.max_clamp {
			-webkit-line-clamp: 5;
		  }
		  &.min_clamp {
			-webkit-line-clamp: 2;
		  }
		  &.no_tags {
			-webkit-line-clamp: 3;
		  }
		}
		& .see_more {
		  display: block;
      padding-top: 2px;
		}
    & .android {
      padding-top: 0px !important;
    }
    &. ios {
      padding-top: 2px !important;
    }
  }

  @media screen and (max-width: 1024px) {

    & .item_name {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    & .item_description {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      &.max_clamp {
        -webkit-line-clamp: 5;
      }
	  &.with_tags {
        -webkit-line-clamp: 3;
      }
      &.min_clamp {
        -webkit-line-clamp: 2;
      }
      &.no_tags {
        -webkit-line-clamp: 3;
      }
    }
    & .see_more {
      display: block;
    }
  }
`
