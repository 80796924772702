import React from 'react'
import cn from 'classnames'

const Pill = ({ index, name, isActive, onClick, className, disabled, type, formattedDataByCategory }) => {
  const category = formattedDataByCategory.find(e => e.category === name)
  let count = ''
  if (category) {
    count = category.all
      .filter(e => e.best_match)
      .length
  }
  return (
    <div
      className={cn(
        'pill navigation-pill',
        {
          'border-b-[3px] border-sandybrown text-black font-bold': isActive,
          'border-b-[2px] border-whitesmoke text-darkgray': !isActive,
          'md:mt-2': type === 'category',
          'active': isActive
        }
      )}
    >
      <button
        id={`${type}-${name}`}
        // disabled={disabled || isActive}
        className={cn(
          "text-sm md:text-lg px-3 py-1 rounded-full whitespace-nowrap after:content-[attr(data-count)] after:text-xs md:after:text-sm",
          className,
          // {
          //   'bg-sandybrown text-white': isActive,
          //   'bg-whitesmoke text-darkgray': !isActive,
          //   'md:mt-2': type === 'category'
          // }
        )}
        data-count={count ? ` (${count})` : ''}
        data-category={name}
        onClick={onClick}
      >
        {name}
      </button>

    </div>
  )
}

export default Pill