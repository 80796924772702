import React, { useEffect, useState, useRef } from 'react'
import { useSpring, animated } from '@react-spring/web'
import { useDrag } from '@use-gesture/react'
import styled from 'styled-components'
import cn from 'classnames'
import intersection from 'lodash/intersection'
import XCircleIcon from '@heroicons/react/solid/XCircleIcon'
import { ArrowUpIcon } from '@heroicons/react/outline'
import { transform, update } from 'lodash'

const defaultPersonalize = {
  hungry_for: ['Seafood', 'Chicken','Meat', 'Pasta',  'Pizza','Bread', 'Veggies' , 'Dessert','View All'],
  special_diet: ['Vegetarian', 'Low Carb', 'Low Fat','None'],
  food_restrictions: ['No Eggs', 'No Nuts', 'No Shellfish', 'No Dairy', 'No Soybean', 'No Gluten','None']
}

const maximumY = 0
const BottomSheet = ({
  bottomSheetRef,
  isOpen,
  onToggle,
  personalize,
  onPersonalize,
  hasPersonalized,
  clearOptions,
  setHasBottomSheetChanges,
  setBottomSheetOpen,
  onRemovePill,
  showScrollToTop,
  scrollToTop
}) => {
  const ref = useRef()
  const headerRef = useRef()
  const [minimumY, setMinimumY] = useState(0);
  const [isMaximize, setIsMaximize] = useState(false);
  const [{ y }, api] = useSpring(() => ({ y: minimumY }));
  const [isDrag, setIsDrag] = useState(false);

  

	const bind = useDrag(({ down, movement: [, my] }) => {
		if (my === 0) return
		setIsDrag(down)
		if (down) {
		  if (!isMaximize) {
			my = my + minimumY
		  }
		  if(isMaximize) {
			if(my < 0) return
			api.start({ y : my, immediate: down }, { axis: 'y' })
		  } else {
			if(my < 0) return
			api.start({ y : my, immediate: down }, { axis: 'y' })
		  }
		} else {
		  const toTop = isMaximize
			? my < 75
			: my < -75
		  const newMy = toTop
			? maximumY
			: minimumY
		  setIsMaximize(toTop)
		  setIsDrag(false)
		  api.start({ y : newMy, immediate: down }, { axis: 'y' })
		}
	  })

  const minimize = (to = minimumY) => {
    setIsMaximize(false)
    api.start({ y: to, immediate: true }, { axis: 'y' })
  }
  const maximize = () => {
    setIsMaximize(true)
    api.start({ y: maximumY, immediate: true }, { axis: 'y' })
  }
  useEffect(() => {
    // const initMinimumY = ref.current.clientHeight * .9
    // const initMinimumY = ref.current.clientHeight - (window.innerHeight * .1)
    const initMinimumY = ref.current.clientHeight - (headerRef.current.clientHeight * 2)
    setMinimumY(initMinimumY)
    minimize(initMinimumY)
  }, [ref])
  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto'
    if (isOpen) {
      maximize()
    }
  }, [isOpen])
  useEffect(() => {
    if (!isMaximize) {
      onToggle()
      setBottomSheetOpen(false)
    } else {
      setBottomSheetOpen(true)
    }
  }, [isMaximize])
  const isDisabled = (key, value) => {
    if (personalize.special_diet.includes('Vegetarian') && ['Seafood', 'Chicken', 'Meat'].includes(value)) {
      return true
    }
    if (personalize.special_diet.includes('Low Carb') && ['Pasta', 'Pizza', 'Bread', 'Dessert'].includes(value)) {
      return true
    }
    if (value === 'Low Carb' && intersection(['Pasta', 'Pizza', 'Bread', 'Dessert'], personalize.hungry_for).length){
      return true
    }
    if (value === 'Vegetarian' && personalize.hungry_for.some(e => ['Seafood', 'Meat', 'Chicken'].includes(e))) {
      return true
    }
    return false
  }
  const clickPill = (...props) => {
    setHasBottomSheetChanges(true)
    onPersonalize(...props)
  }
  const onClear = () => {
    setHasBottomSheetChanges(true)
    clearOptions()
	setIsMax(0)
  }

  const onClose = () => {
    onToggle()
    minimize()
	setIsMax(0)
  }
  const getPillBtn = (key, value) => (
    <button key={value} className={cn(
      'pill text-xs ml-1 whitespace-nowrap mt-2 px-3 py-2  text-white rounded-full font-bold flex items-center',
      {
        'bg-yellowgreen': key === 'special_diet',
        'bg-mediumslateblue': key === 'hungry_for',
        'bg-red': key === 'food_restrictions'
      }
    )}>
      {value}
      <XCircleIcon
        className='h-6 ml-[2px]'
        onClick={(e) => {
          e.stopPropagation()
          clickPill(key, value)
          onRemovePill()
        }}
      />
    </button>
  )
  
 

  const onClickBottomSheet = () => {
    onClose()
  }
  const onClickBottomSheetBody = (e) => {
    if (isOpen) {
      e.stopPropagation()
    }
  }

const [isMax, setIsMax] = useState(0);
const refP = useRef(null);

useEffect(() => {
	const updateHeight = () => {
	  if (refP.current) {
		const currHeight = refP.current.clientHeight;
		setIsMax(currHeight);
	  }
	};
	
	updateHeight(); 
	
	window.addEventListener('resize', updateHeight); 
	window.addEventListener('scroll', updateHeight);
	
	return () => {
	  window.removeEventListener('resize', updateHeight); 
	  window.removeEventListener('scroll', updateHeight);
	};
  }, [refP]);

  return (
    <>
	{(showScrollToTop && !isOpen) && (
           <div id='slide1' className={cn(
			`fixed z-10`,
			isDrag ? 'hidden' : 'block',
			'right-[18px]',
		  )}
		  style={{ 
			...(hasPersonalized 
				? {bottom: `${isMax + 80}px`} 
				: {bottom: `${isMax + 90}px`}
				)
			 }}>
            <div className='w-full flex justify-end lg:w-[700px] lg:mx-auto z-[10]'>
              <button
                className='h-[2.5rem] w-[2.5rem] md:h-14 md:w-14 bg-sandybrown cursor-pointer rounded-full'
                onClick={(e) => {
                  e.stopPropagation()
                  scrollToTop()
                }}
              >
                <ArrowUpIcon className='text-white w-[1.6rem] mx-auto' />
              </button>
            </div> 
          </div>
        )}
      {(isOpen) && (
        <div className='fixed top-0 left-0 h-screen w-full bg-black/70 z-[10]' onClick={onClose}></div>
      )}
      <animated.div {...bind()} ref={ref}  style={{ y, touchAction: 'none' }} className='fixed bottom-0 w-full z-10'>

        <StyledBottomSheet
          ref={bottomSheetRef}
          className={cn(
            'w-full border-t-4 border-[#303444] bg-[#303444] rounded-t-2xl z-11 lg:w-[700px]',
          )}
          onClick={onClickBottomSheet}
        >
          <div className='w-full bg-[#303444]' ref={headerRef}>
            <div className='flex justify-center mt-3 cursor-pointer' onClick={onClose}>
              <div className='h-1 w-12 bg-sandybrown rounded-full'></div>
            </div>
            <div className='mt-2 '>
              <div className='flex justify-center cursor-pointer' onClick={onClose}>
                <h2 className={`uppercase text-xl text-[#E2B369] md:text-xl ${(hasPersonalized && !isOpen) ? 'hidden' : ''}`}>Personalize My Menu</h2>
              </div>
            </div>
          </div>
          {(!isMaximize && hasPersonalized) && (
            <div ref={refP} className='flex flex-wrap justify-center items-center mb-6 '>
              {personalize.hungry_for.map(tag => getPillBtn('hungry_for', tag))}
              {personalize.special_diet.map(tag => getPillBtn('special_diet', tag))}
              {personalize.food_restrictions.map(tag => getPillBtn('food_restrictions', tag))}
            </div>
          )}
          <div className='p-4 mt-2 md:mt-4 bg-white' onClick={onClickBottomSheetBody}>
          <h2 className='text-black mt-2 text-lg md:text-2xl font-bold'>What are you hungry for today?</h2>
            <div className='flex flex-wrap'>
              {defaultPersonalize.hungry_for.map(e => (
                <button
                  disabled={isDisabled('hungry_for', e)}
                  key={e}
                  className={cn(
                    'pill text-lg md:text-2xl ml-2 whitespace-nowrap mt-2 rounded-full px-3 py-1',
                    {
                      'bg-mediumslateblue text-white': (personalize.hungry_for.length === 0 && e.includes('View All')) || (personalize.hungry_for.includes(e) && !isDisabled('special_diet', e)),
                      'bg-whitesmoke text-darkgray': (personalize.hungry_for.length !== 0 && e.includes('View All')) || (!e.includes('View All') && !personalize.hungry_for.includes(e) && !isDisabled('special_diet', e)),
                      'bg-white text-slate-300 ': isDisabled('special_diet', e),
                    }
                  )}
                  onClick={clickPill.bind(null, 'hungry_for', e)}
                >{e}</button>
              ))}
            </div>
            <h2 className='text-black text-lg md:text-2xl font-bold pt-[1.5rem]'>Are you following a special diet?</h2>
            <div className='flex flex-wrap'>
              {defaultPersonalize.special_diet.map(e => (
                <button
                  disabled={isDisabled('special_diet', e)}
                  key={e}
                  className={cn(
                    'pill text-lg md:text-2xl ml-2 whitespace-nowrap mt-2 rounded-full px-3 py-1',
                    {
                      'bg-yellowgreen text-white':  (personalize.special_diet.length === 0 && e.includes('None')) || (personalize.special_diet.includes(e) && !isDisabled('special_diet', e)),
                      'bg-whitesmoke text-darkgray':  (personalize.special_diet.length !== 0 && e.includes('None')) || (!e.includes('None') && !personalize.special_diet.includes(e) && !isDisabled('special_diet', e)),
                      'bg-white text-slate-300': isDisabled('special_diet', e)
                    }
                  )}
                  onClick={clickPill.bind(null, 'special_diet', e)}
                >{e}</button>
              ))}
            </div>
            
            <h2 className='text-black mt-[1.5rem] text-lg md:text-2xl font-bold'>Do you have any food restrictions?</h2>
            <div className='flex flex-wrap'>
              {defaultPersonalize.food_restrictions.map(e => (
                <button
                  disabled={isDisabled('food_restrictions', e)}
                  key={e}
                  className={cn(
                    'pill text-lg md:text-2xl ml-2 whitespace-nowrap mt-2 rounded-full px-3 py-1',
                    {
                      'bg-red text-white': (personalize.food_restrictions.length === 0 && e.includes('None')) || (personalize.food_restrictions.includes(e)),
                      'bg-whitesmoke text-darkgray': (personalize.food_restrictions.length !== 0 && e.includes('None')) || (!e.includes('None') && !personalize.food_restrictions.includes(e)),
                      'bg-red text-whitesmoke': personalize.food_restrictions.includes(e)
                    }
                  )}
                  onClick={clickPill.bind(null, 'food_restrictions', e)}
                >{e}</button>
              ))}
            </div>
            
            <div className='mt-4 relative w-full grid grid-cols-2 gap-2'>
              <button
                disabled={!hasPersonalized}
                className={cn(
                  'cursor-pointer text-xl md:text-2xl px-5 py-1 whitespace-nowrap',
                  {
                    'bg-whitesmoke text-[#c2c2c2]': !hasPersonalized,
                    'bg-darkgray text-white border-transparent': hasPersonalized
                  }
                )}
                onClick={onClear}
              >
                Clear Options
              </button>
              <button
                className='cursor-pointer px-10 py-1 text-xl md:text-2xl bg-sandybrown text-white border-transparent'
                onClick={onClose}
              >
                Apply
              </button>
            </div>
          </div>
        </StyledBottomSheet>
      </animated.div>
    </>
  )
}

export default BottomSheet

const StyledBottomSheet = styled.div`
  transition: 0.5s ease-in-out;
`