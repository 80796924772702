import React from 'react'
import cn from 'classnames'
import MenuCardItem from '../components/MenuCardItem'

const List = ({
  activeCategory,
  personalize,
  hasPersonalized,
  showAll,
  showAllLoading,
  isWideScreen,
  scrollToTop,
  showScrollToTop,
  dataByCategory,
  formattedDataByCategory,
  hasBottomSheetChanges,
  isBottomSheetOpen,
  displayItems,
  onClickImage,
  viewAll,
  bottomHeaderHeight,
  device
}) => {
  const { food_restrictions,hungry_for,special_diet } = personalize
  
  const filterMenuItems = (section) => {
    const getList = () => {
      if(!viewAll){
        //no personalize or no filter
        if(food_restrictions.length === 0 && hungry_for.length === 0 && special_diet.length === 0) {
          return section.all
        }
        //show bestmatch and partialmatch only
        if(food_restrictions.length !== 0 || hungry_for.length !== 0 || special_diet.length !== 0) {
          const filteredSection = section.all.filter((e,index) => e.best_match || (!e.best_match && !!e.tagCount))
          return filteredSection
        }
      }
  
      //show all
      if(viewAll && (food_restrictions.length !== 0 || hungry_for.length !== 0 || special_diet.length !== 0)){
        return section.all
      }
  
      return section.all
    }
    
    const list = getList()

    // best match first
    const bestMatchItems = list.filter(e => e.best_match)
    const notBestMatchItems = list.filter(e => !e.best_match)
    return [...bestMatchItems, ...notBestMatchItems]
  }

  return (
    <div>
          <div
            className={cn(
              'flex flex-col w-full px-4 mt-5 sticky top-[111px] mb-[15vh]',
              {
                'mb-[15vh] lg:mb-[17vh]': !((hasPersonalized && !showAll))
              }
            )}
            style={{
              marginBottom: `${bottomHeaderHeight}px`
            }}
          >
            {formattedDataByCategory.map((section, index) => {
                  return (
                    <div
                      id={section.category}
                      key={section.category}
                      className={cn("section", { "pt-4": index !== 0 })}
                    >
                      <p className="text-sm md:text-2xl pb-2 font-bold">
                        {section.category}
                      </p>
                      {hasPersonalized && (
                        <p className="text-[.8rem] md:text-[1rem]">
                          {section.description}
                        </p>
                      )}
                      <div className='section-list'>
                      {filterMenuItems(section)?.map((e, index) => {
                        return (
                          <MenuCardItem
                            {...e}
                            key={e.id}
                            index={index}
                            personalize={personalize}
                            hasBottomSheetChanges={hasBottomSheetChanges}
                            isWideScreen={isWideScreen}
                            hasPersonalized={hasPersonalized}
                            onClickImage={onClickImage}
                            device={device}
                            // onClick={goToItemDetails.bind(null, e)}
                          />
                        );
                      })}
                      </div>
                    </div>
                  );
              })}
            {(showAllLoading) && (
              <div className='flex justify-center mt-4 mb-[11vh] lg:mb-[13vh]'>
                <h1 className='text-center mt-8'>Searching Other {activeCategory}...</h1>
              </div>
            )}
          </div>
        </div>
  )
}

export default List