import React, { useState, useEffect } from 'react'
import Menu from "./pages/Menu";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import flatten from 'lodash/flatten'
import uniq from 'lodash/uniq'
import differenceBy from 'lodash/differenceBy'
import debounce from 'lodash/debounce'
import Colors from "./pages/Colors";
import ItemDetails from "./pages/ItemDetails";
import { requestData, autoTagFoodItems, filterMenuItems, groupMenuItemsByCategory } from './data/tools';
import useFields from './hooks/useFields'
import intersection from 'lodash/intersection'
import sortBy from 'lodash/sortBy'

const categories = [
  'Specials', 'House Delights', 'Pizza & Breads', 'Soups & Salads',
  'Sides', 'Pasta', 'Filled Pastas', 'Entrees', 'Dessert'
]

const defaultPersonalize = {
  hungry_for: [],
  special_diet: [],
  food_restrictions: []
}

const defaultData = {
  menu_items: [],
  food_items: [],
  food_tags: []
}

const defaultDataByCategory = []

function App() {
  const [fields, setFields] = useFields({
    data: defaultData,
    dataByCategory: defaultDataByCategory,
    displayCategories: categories,
    displayItems: [],
    activeCategory: categories[0],
    item: null,
    personalize: { ...defaultPersonalize },
    loading: false,
    hasPersonalized: false,
    showAll: false,
    showAllLoading: false,
    isWideScreen: false,
    showScrollToTop: false,
    viewAll : false,
    device: ''
  }) 
  const {
    data, displayItems, activeCategory,
    personalize, showAll, displayCategories, isWideScreen
  } = fields
  // const [isWideScreen, setIsWideScreen] = useState(false)
  const isDataReady = data.menu_items.length
    || data.food_items.length
    || data.food_tags.length
  

  const scrollToCategory = category => {
    const categoryIndex = displayCategories.findIndex(e => e === category)
    const categoryElement = document.querySelector(`#pills-container #category-${categoryIndex}`)
    if (categoryElement) {
      categoryElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
    }
  }
  
  const handleFilter = (category, tags = [], params = {} ) => {
    setFields({
      activeCategory: category
    })
    const groupedByCategory = groupMenuItemsByCategory(data.menu_items, tags, personalize)
    setFields({
      dataByCategory: groupedByCategory
    })
    scrollToCategory(category)
    filterMenuItems(data.menu_items, tags, personalize).then(res => {
      setFields({
        displayItems: [...res],
        showAll: false,
        loading: false
      })
    })  
  }
  const handlePersonalize = (key, value) => {
    const exists = personalize[key].includes(value)
    if (key === 'special_diet' && !exists) {
      personalize[key] = [value]
    }
    if (!exists && value === 'None') {
      personalize[key] = []
      personalize[key] = uniq(personalize[key])
      setFields({
        personalize: { ...personalize },
        hasPersonalized: !!flatten(Object.values(personalize)).length
      })
      return;
    }
    if (!exists && value !== 'None') {
      personalize[key] = personalize[key].filter(e => e !== 'None')
    }
    if (!exists && value === 'Vegetarian') {
      personalize.hungry_for = personalize.hungry_for.filter(e => !['Seafood', 'Chicken', 'Meat'].includes(e))
    }
    if (!exists && value === 'Low Carb') {
      personalize.hungry_for = personalize.hungry_for.filter(e => !['Pasta', 'Pizza', 'Bread'].includes(e))
    }
    if(!exists && value === 'View All'){
      personalize[key] = []
      personalize[key] = uniq(personalize[key])
      setFields({
        personalize: { ...personalize },
        hasPersonalized: !!flatten(Object.values(personalize)).length
      })
      return;
    }
    personalize[key] = exists ? personalize[key].filter(e => e !== value) : [...personalize[key], value]
    personalize[key] = uniq(personalize[key])
    setFields({
      personalize: { ...personalize },
      hasPersonalized: !!flatten(Object.values(personalize)).length
    })
  }
  const toggleShowAll = () => {
    setFields({
      showAll: !showAll
    })
    if (!showAll) {
      setFields({
        showAllLoading: true
      })
      filterMenuItems(data.menu_items, [], personalize).then(res => {
        const otherItems = differenceBy(res, displayItems, 'id')
        setFields({
          displayItems: [...displayItems, ...otherItems],
          showAllLoading: false
        })
      })
    }
  }
  const clearOptions = () => {
    setFields({
      personalize: { ...defaultPersonalize },
      hasPersonalized: false
    })
  }
  const setItem = (item) => {
    setFields({
      item
    })
  }

  const disableNavigationPills = (bool = true) => {
    const allPills = document.querySelectorAll('.navigation-pill button')
    Array.from(allPills).forEach(el => {
      el.disabled = bool
    })
  }

  const viewAllToggler = (bool) => {
    setFields({
      ...fields,
      viewAll : bool ? bool : !fields.viewAll
    })
  }

  const detectDevice = () => {
    const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;
    
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return setFields({device: "windows phone"});
    }

    if (/android/i.test(userAgent)) {
      return setFields({device: "android"});
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return setFields({device: "ios"});
    }

    return setFields({device: "other"});
  }

  useEffect(() => {
    if (isDataReady) {
      setFields({
        dataByCategory: groupMenuItemsByCategory(data.menu_items, [], personalize)
      })
      handleFilter(activeCategory)
    }
  }, [isDataReady])
  useEffect(() => {
    requestData().then(res => {
      const tagged = autoTagFoodItems(res)
      const availableCategories = uniq(tagged.menu_items.map(e => e.category))
      setFields({
        data: tagged,
        displayCategories: intersection(categories, availableCategories)
      })
    })
    detectDevice()
  }, [])

  useEffect(() => {
    const handleResize = debounce(() => {
      // setIsWideScreen(window.innerWidth >= 768)
      setFields({
        isWideScreen: window.innerWidth >= 768
      })
    }, 100)
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const activateCategory = (newActiveCategory) => {
    const pillContainer = document.getElementById('pills-container')
    if (pillContainer) {
      const category = document.getElementById(`category-${newActiveCategory}`)
      const prevActiveCategory = document.querySelector('.pill.active')
      const prevActiveCategoryName = document.querySelector('.pill.active button')?.dataset?.category
      if (category && newActiveCategory !== prevActiveCategoryName) {
        if (prevActiveCategory) {
          prevActiveCategory.classList.add('border-b-[2px]', 'border-whitesmoke', 'text-darkgray')
          prevActiveCategory.classList.remove('border-b-[3px]', 'border-sandybrown', 'text-black', 'active', 'font-bold')
        }
        category.closest('.pill').classList.remove('border-b-[2px]', 'border-whitesmoke', 'text-darkgray')
        category.closest('.pill').classList.add('border-b-[3px]', 'border-sandybrown', 'text-black', 'active', 'font-bold')
        setFields({ activeCategory: newActiveCategory })
        pillContainer.scrollTo({
          behavior: 'smooth',
          left: newActiveCategory === 'Specials'
            ? 0
            : category.offsetLeft - 130
        })
      }
    }
  }

  useEffect(() => {
    const scrollFunction1 = () => {
      setFields({ 
        showScrollToTop: document.body.scrollTop > 20 || document.documentElement.scrollTop > 20
      })
    }
    const scrollFunction2 = debounce(() => {
      disableNavigationPills(false)
      const sections = document.getElementsByClassName('section-list')
      const headerHeight = document.querySelector('.header')?.clientHeight || 0
      const pillsContainerHeight = document.querySelector('#pills-container')?.clientHeight || 0
      const vielAllToggleContainer = document.querySelector('.view-all-toggle')?.clientHeight || 0
      const totalHeaderHeight = headerHeight + pillsContainerHeight + vielAllToggleContainer + 40
      const getDistanceFromHeaderBottom = (distanceFromTop) => {
        return Math.abs(
          totalHeaderHeight > distanceFromTop
            ? totalHeaderHeight - distanceFromTop
            : distanceFromTop - totalHeaderHeight
        )
      }
      const categoryDistances = Array.from(sections).map((el, index) => {
        const categoryName = displayCategories[index]
        const sectionTopDistance = getDistanceFromHeaderBottom(el.getBoundingClientRect().top)
        const sectionBottonDistance = getDistanceFromHeaderBottom(el.getBoundingClientRect().bottom)
        return {
          categoryName,
          distanceFromHeaderBottom: sectionTopDistance < sectionBottonDistance
            ? sectionTopDistance
            : sectionBottonDistance
        }
      })
      const nearestCategory = sortBy(categoryDistances, 'distanceFromHeaderBottom')[0]?.categoryName
      activateCategory(nearestCategory)
    }, 200)
    window.addEventListener('scroll', scrollFunction1)
    window.addEventListener('scroll', scrollFunction2)
    return () => {
      window.removeEventListener('scroll', scrollFunction1)
      window.removeEventListener('scroll', scrollFunction2)
    }
  }, [displayCategories])

  const scrollToTop = () => {
    window.scrollTo({ behavior: 'smooth', top: 0 })
    activateCategory('Specials')
    // document.body.scrollTop = 0
    // document.documentElement.scrollTop = 0
  }

  const props = {
    ...fields,
    setItem,
    onFilter: handleFilter,
    onPersonalize: handlePersonalize,
    toggleShowAll,
    clearOptions,
    scrollToTop,
    disableNavigationPills,
    viewAllToggler,
    activateCategory
  }

  if (!isDataReady) {
    return <div className='fixed top-0 left-0 w-full h-screen bg-black'></div>
  }
  return (
    <div className='w-full h-full'>
      <div className='fixed top-0 left-0 w-full h-screen lg:bg-black -z-20'></div>
      <div className='w-full h-full lg:w-[700px] lg:mx-auto bg-white'>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Menu {...props}/>}/>
            <Route path="/item" element={<ItemDetails {...props}/>}/>
            <Route path="/colors" element={<Colors />}/>
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
